import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import { green, grey, yellow, blue, lightGreen } from "@mui/material/colors";
import { styled, useTheme } from "@mui/material/styles";
import { Button } from "antd";
import { IconButton, Menu, MenuItem, Modal, useMediaQuery } from "@mui/material";
import Loading from "../Loading";
import GuestList from "./guestsList";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";
import { useNavigate } from "react-router-dom";
import CheckIn from "../CheckIn/Index";
import GuestInfoModal from "../Roomsnew/GuestInfoModal";
import RoomsFilters from "../Shaxmatka/Filters/Filters";
import RoomsSort from "../Shaxmatka/Sorts/Sort";

// Icons
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--busy": {
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[600],
    },
    "&.Mui-selected": {
      backgroundColor: green[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: green[800],
      },
    },
  },
  "& .super-app-theme--dirty": {
    backgroundColor: grey[600],
    "&:hover": {
      backgroundColor: grey[600],
    },
    "&.Mui-selected": {
      backgroundColor: grey[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: grey[800],
      },
    },
  },
  "& .super-app-theme--cleaning.in.progress": {
    backgroundColor: yellow[600],
    "&:hover": {
      backgroundColor: yellow[600],
    },
    "&.Mui-selected": {
      backgroundColor: yellow[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: yellow[800],
      },
    },
  },
  "& .super-app-theme--cleaning.complete": {
    backgroundColor: lightGreen[600],
    "&:hover": {
      backgroundColor: lightGreen[600],
    },
    "&.Mui-selected": {
      backgroundColor: lightGreen[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: lightGreen[800],
      },
    },
  },
  "& .super-app-theme--in.maintains": {
    backgroundColor: blue[600],
    "&:hover": {
      backgroundColor: blue[600],
    },
    "&.Mui-selected": {
      backgroundColor: blue[800],
      color: "#fff",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: blue[800],
      },
    },
  },
}));

function DataTable() {
  const [guests, setGuests] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedRows, setSelectedRows] = useState([])
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState(0);
  const [busy, setBusy] = useState(0);
  const [dirty, setDirty] = useState(0);
  const [clean, setClean] = useState(0);
  const [cleanComplete, setCleanComplete] = useState(0);
  const [maintains, setMaintains] = useState(0);
  const [filtersModal, setFiltersModal] = useState(false);
  const [sortsModal, setSortsModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation()

  // Filter
  const [filter, setFilterProp] = useState({
    number: null,
    status: "",
    category: "",
    priceRange: [0, Infinity],
    minValue: 0,
    maxValue: Infinity
  });

  // Sort
  const [sort, setSortProp] = useState({
    sortPrice: "asc",
    sortNumber: "asc",
  });

  const [openGuestlist, setOpenGuestlist] = useState(false);
  const handleOpenGuestlist = () => setOpenGuestlist(true);
  const handleCloseGuestlist = () => setOpenGuestlist(false);

  const [openCheckin, setOpenCheckin] = useState(false);
  const handleOpenCheckin = () => setOpenCheckin(true);
  const handleCloseCheckin = () => setOpenCheckin(false);
  // const getStatus = async () => {
  //   await axios
  //     .get(`${ENV.API_URL}/hdp-gateway/room/charts`, {
  //       headers: {
  //         authorization: sessionStorage.getItem("token"),
  //       },
  //     })
  //     .then((res) => {
  //       res.data?.data?.forEach((element) => {
  //           if (element.label === "available") {
  //             setAvailable(element.value);
  //           } else if (element.label === "busy") {
  //             setBusy(element.value);
  //           } else if (element.label === "dirty") {
  //             setDirty(element.value);
  //           } else if (element.label === "cleaning complete") {
  //             setCleanComplete(element.value);
  //           } else if (element.label === "cleaning in progress") {
  //             setClean(element.value);
  //           } else {
  //             setMaintains(element.value);
  //           }
  //         });
  //     })
  //     .catch((err) => {
  //       toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred"));
  //     });
  // };
  const [anchorEl, setAnchorEl] = useState(null);

  const getAxios = async () => {
    try {
      setLoading(true);

      const token = sessionStorage.getItem("token");

      const categoriesResponse = await axios.get(
        `${ENV.API_URL}/hdp-gateway/room/category/list`,
        {
          headers: { Authorization: token },
        }
      );
      setCategories(categoriesResponse.data.data);

      const roomsResponse = await axios.get(
        `${ENV.API_URL}/hdp-gateway/room`,
        {
          headers: { Authorization: token },
        });

      const rooms = roomsResponse.data.data;

      setFilterProp((prev) => {
        return { ...prev, minValue: Math.min(...rooms.map((room) => room.price)), maxValue: Math.max(...rooms.map((room) => room.price)) }
      });

      setRooms(rooms);
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.errors[0]?.message || t("An error occurred"));
      setLoading(false);
    }
  };

  useEffect(() => {
    getAxios();
  }, []);

  useEffect(() => {
    setFilterProp((prev) => {
      return { ...prev, minValue: Math.min(...rooms.map((room) => room.price)), maxValue: Math.max(...rooms.map((room) => room.price)) }
    });
  }, [rooms]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    { field: "number", headerName: t("Room number"), width: 130, align: "center" },
    {
      field: "category_name",
      headerName: t("Category"),
      width: 260,
      align: "left",
    },
    {
      field: "number_of_people",
      headerName: t("Number of people"),
      width: 150,
      align: "center",
    },
    {
      field: "price",
      headerName: t("Price"),
      width: 260,
      align: "center",
    },
    {
      field: "status_name",
      headerName: t("Status"),
      width: 260,
      align: "center",
    },
    {
      field: "action",
      headerName: t("Actions"),
      width: 221,
      align: "right",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon style={{ color: "#fff" }} />
            </IconButton></>
        );
      },
    },
  ];

  const onRowsSelectionHandler = (ids) => {
    setGuests([]);
    setTimeout(() => {
      const selectedRowsData = ids.map((id) =>
        rooms.find((row) => row.id === id)
      );

      setSelectedRows(selectedRowsData);
      setGuests(selectedRowsData[0]?.guests);
    }, 500);
  };

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMd = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLg = useMediaQuery(theme.breakpoints.between("lg", "xl"));

  const currentRooms = rooms
    .filter((room) => {
      if (filter.number) {
        return room?.number?.toLowerCase().includes(filter.number?.toLowerCase());
      }
      return true;
    })
    .filter((room) => {
      if (filter.status) {
        return room.status_name === filter.status;
      }
      return true;
    })
    .filter((room) => {
      if (filter.category) {
        return room.category_name === filter.category;
      }
      return true;
    })
    .filter((room) => {
      return room.price >= (filter.priceRange[0] || 0) && room.price <= (filter.priceRange[1] || Infinity)
    })
    .sort((a, b) => {
      if (sort.sortNumber === "asc") {
        return a.number - b.number;
      } else {
        return b.number - a.number;
      }
    })
    .sort((a, b) => {
      if (sort.sortPrice === "asc") {
        return a.price - b.price;
      } else {
        return b.price - a.price;
      }
    })
    ;

  const gridItemSize = isXs ? 12 : isSm ? 6 : isMd ? 4 : isLg ? 3 : 3;

  const navigate = useNavigate();
  return (
    <div className="container mt-3">
      <h5>{t("All rooms")}</h5>
      <div>
        {(rooms.length > 0 && !loading) && <>
          <div style={{ display: "flex", justifyContent: "flex-end", padding: "0 0 5px 0", gap: "5px" }}>
            <Button type="primary" onClick={() => setFiltersModal(true)}>
              <TuneRoundedIcon />
            </Button>
            <Button type="primary" onClick={() => setSortsModal(true)}>
              <SortRoundedIcon />
            </Button>
          </div>
          <RoomsFilters
            show={filtersModal}
            setShow={setFiltersModal}
            categories={categories}
            filterProp={filter}
            setFilterProp={setFilterProp}
            getDataWithFilter={getAxios}
          />
          <RoomsSort
            show={sortsModal}
            setShow={setSortsModal}
            sortProp={sort}
            setSortProp={setSortProp}
            getDataWithFilter={getAxios}
          />
        </>
        }
        {loading ? (
          <Loading />
        ) : (
          <Box>
            <StyledDataGrid
              {...currentRooms}
              initialState={{
                ...rooms.initialState,
                pagination: { paginationModel: { pageSize: 5 } },
              }}
              pageSizeOptions={[5, 10, 25, 100]}
              autoHeight
              rows={currentRooms}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              getRowClassName={(params) =>
                `super-app-theme--${params.row.status_name}`
              }

              onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
            />
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: '20ch',
                  overflow: 'visible',
                  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
                  borderRadius: '8px',
                  marginTop: '45px',
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem disabled={selectedRows[0]?.status !== 1}
                onClick={handleOpenCheckin}
              >
                {t("Check in")}
              </MenuItem>
              <MenuItem disabled={selectedRows[0]?.status === 1} onClick={() => navigate(`/housekeeping-assignmentv2`)}>{t("Room assignment")}</MenuItem>
              <MenuItem
                onClick={handleOpenGuestlist}
                disabled={selectedRows[0]?.status !== 2}>{t("Guest")}</MenuItem>
              <MenuItem disabled={selectedRows[0]?.status !== 2} onClick={() => navigate(`/check-out/${rooms?.guests[0]?.booking_id}`)} >{t("Check out")}</MenuItem>
            </Menu>

            <Modal
              open={openCheckin}
              onClose={handleCloseCheckin}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              BackdropProps={{
                onClick: handleCloseCheckin,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  borderRadius: '8px',
                  maxWidth: '780px',
                  maxHeight: '90%',
                  overflowY: "scroll"
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleCloseCheckin}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <CheckIn />
              </Box>
            </Modal>

            <Modal
              open={openGuestlist}
              onClose={handleCloseGuestlist}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              BackdropProps={{
                onClick: handleCloseGuestlist,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                  borderRadius: '8px',
                  maxWidth: '780px',
                  width: '100%',
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleCloseGuestlist}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                {guests && <GuestInfoModal guestData={guests} />}
              </Box>
            </Modal>
          </Box>
        )}
      </div>
      {guests.length ? (
        <div className="grid_border pb-3 mb-3">
          <GuestList data={guests} />
        </div>
      ) : null}
    </div>
  );
}

export default DataTable;