import React, { useState, useEffect } from "react";
import styles from "../modal-styles.module.css";
import { useTranslation } from "react-i18next";
import { Button, Grid, MenuItem, Select, FormControl, InputLabel } from "@mui/material";

export default function ShaxmatkaFilters({ setShow, show, sortProp, setSortProp, getDataWithFilter }) {
    const { t } = useTranslation();
    const [showtrue, setShowTrue] = useState(false);
    useEffect(() => setShowTrue(show), [show]);
    const [sideStyle, setSideStyle] = useState({});

    const [sort, setSort] = useState({
        sortNumber: "asc",
        sortPrice: "asc"
    });

    useEffect(() => {
        setSort(sortProp);
    }, [sortProp]);

    const resize = () => {
        if (window?.innerWidth && window?.innerWidth < 576) setSideStyle({ height: "100%", width: "100%", top: "0" });
        else setSideStyle({ height: "calc(100% - 64px)", width: "480px", top: "64px" });
    };

    useEffect(resize, []);
    window.onresize = resize;

    const close = (e) => {
        if (e.currentTarget === e.target) hide();
    };

    const hide = () => {
        setShowTrue(false);
        setTimeout(() => setShow(false), 300);
    };

    function handleFilter(val) {
        setSortProp(val);
        hide();
    }

    function handleReset() {
        setSort(() => {
            return {
                sortNumber: "asc",
                sortPrice: "asc"
            }
        });
    }

    const gridItemSize = 6;

    return (
        <div className={showtrue ? styles.fade : undefined} onClick={close}>
            <div className={styles.modal} style={{ ...sideStyle, right: showtrue ? "0" : "-480px" }}>
                <div className={styles.title}>
                    <span className={styles.close} onClick={hide}>
                        &#x2715;
                    </span>
                </div>
                <div style={{ display: "flex" }}>
                    <Grid container spacing={0} alignItems="center" style={{ paddingTop: 0, alignItems: "flex-start" }}>
                        <Grid item xs={gridItemSize} style={{ paddingTop: 11 }}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>{t("Sort by number")}</InputLabel>
                                <Select
                                    label={`${t("Sort by number")}`}
                                    value={sort.sortNumber}
                                    onChange={(e) => setSort((prev) => { return { ...prev, sortNumber: e.target.value } })}
                                >
                                    <MenuItem value="asc">{t("Ascending")}</MenuItem>
                                    <MenuItem value="desc">{t("Descending")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={gridItemSize} style={{ paddingLeft: "16px", paddingTop: 11 }}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>{t("Sort by price")}</InputLabel>
                                <Select
                                    label={`${t("Sort by price")}`}
                                    value={sort.sortPrice}
                                    onChange={(e) => setSort((prev) => { return { ...prev, sortPrice: e.target.value } })}
                                >
                                    <MenuItem value="asc">{t("Ascending")}</MenuItem>
                                    <MenuItem value="desc">{t("Descending")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button type="primary" onClick={handleReset}>
                        <span>{t("Reset")}</span>
                    </Button>
                    <Button type="primary" onClick={() => { handleFilter(sort); getDataWithFilter(); }}>
                        <span>{t("Sort")}</span>
                    </Button>
                </div>
            </div>
        </div >
    );
}