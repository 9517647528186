import React, { useState, useEffect } from "react";
import styles from "../modal-styles.module.css";
import styles2 from "./booking-styles.module.css";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { AntDatePicker, AntInput, AntSelect } from "../../../hooks/createAntDFields";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import ENV from "../../../environment";
import { dateDDMMYYYY, dateLocalISO } from "../../../hooks/dateFormatter";
import dayjs from "dayjs";

export default function ShaxmatkaUpdateBooking({ setShow, show, bookingTypes, bookingData, getData, categories }) {
    const { t } = useTranslation();
    const validate = Yup.object({
        first_name: Yup.string().required(t("Field is required")),
        last_name: Yup.string().required(t("Field is required")),
        room_category: Yup.number().required(t("Field is required")),
        room_id: Yup.number().required(t("Field is required")),
        start_date: Yup.string().required(t("Field is required")),
        end_date: Yup.object().required(t("Field is required")),
        type: Yup.number().required(t("Field is required")),
        debit: Yup.number().required(t("Field is required")),
        credit: Yup.number(),
        comments: Yup.string(),
    });

    const change = (values) => {
        let room = rooms.find((e) => e.value === values.room_id);
        if (bookingData.room?.id === values.room_id) room = bookingData.room;
        if (values.end_date?.$d && values.start_date?.$d) values.debit = room?.price * dateDiffInDays(values.start_date?.$d, values.end_date?.$d) || 0;
    };

    const cats = categories.map((e) => ({ value: e.id, label: e.name }));
    const types = bookingTypes.map((e) => ({ value: e.id, label: e.name }));
    const [showtrue, setShowTrue] = useState(false);

    useEffect(() => setShowTrue(show), [show]);

    const [sideStyle, setSideStyle] = useState({});
    const resize = () => {
        if (window?.innerWidth && window?.innerWidth < 576) setSideStyle({ height: "100%", width: "100%", top: "0" });
        else setSideStyle({ height: "calc(100% - 64px)", width: "480px", top: "64px" });
    };
    useEffect(resize, []);
    window.onresize = resize;

    const close = (e) => {
        // if (e.currentTarget === e.target) hide();
    };
    const hide = (resetForm) => {
        setUpdatableForm(true);
        setShowTrue(false);
        if (resetForm) resetForm();
        setTimeout(() => setShow(false), 300);
    };

    const submit = async (data, { resetForm }, setSubmitting) => {
        if (!data.end_date.$d || new Date(dateLocalISO(new Date(data?.start_date))) >= new Date(dateLocalISO(data.end_date?.$d)))
            return toast.error("End date is invalid");

        if (!data?.comments) delete data.comments;
        if (!data?.credit) delete data.credit;
        const body = { ...data, id: bookingData.booking_id, start_date: dateLocalISO(data.start_date?.$d), end_date: dateLocalISO(data.end_date?.$d) };

        await axios
            .put(`${ENV.API_URL}/hdp-gateway/booking`, body, {
                headers: { Authorization: sessionStorage.getItem("token") },
            })
            .then(() => {
                toast.success(t("Success"));
                resetForm();
                setShow(false);
                getData();
            })
            .catch((err) => {
                console.log(err);
                toast.error(t("An error occurred"));
            });
        setSubmitting(false);
    };

    const [rooms, setRooms] = useState([]);
    const [updatableForm, setUpdatableForm] = useState(true);
    useEffect(() => {
        setRooms([]);
    }, [bookingData]);
    const onCategoryChange = async (e, values, setValues) => {
        setUpdatableForm(false);
        if (e) {
            await axios
                .get(`${ENV.API_URL}/hdp-gateway/room/cat/${e}`, {
                    headers: { Authorization: sessionStorage.getItem("token") },
                })
                .then((res) => {
                    setRooms(res.data.data.map((e) => ({ value: e.id, label: e.number, price: e.price })));
                    setValues({ ...values, room_category: e, room_id: undefined });
                })
                .catch((err) => toast.error(err?.response?.data?.errors[0]?.message || t("An error occurred")));
        }
        // setUpdatableForm(true);
    };

    return (
        <div className={showtrue ? styles.fade : undefined} onClick={close}>
            <Formik
                initialValues={{
                    first_name: bookingData.first_name,
                    last_name: bookingData.last_name,
                    start_date: dayjs(dateDDMMYYYY(bookingData.start_date || new Date()), "DD.MM.YYYY"),
                    end_date: dayjs(dateDDMMYYYY(bookingData.end_date || new Date()), "DD.MM.YYYY"),
                    room_category: bookingData.room?.category.id,
                    room_id: bookingData.room?.id,
                    debit: undefined,
                    credit: bookingData.noticeData?.credits || undefined,
                    type: bookingData.bookingType,
                    comments: bookingData.bookingComment || "",
                }}
                enableReinitialize={updatableForm}
                validationSchema={validate}
                onSubmit={submit}
            >
                {({ values, isSubmitting, submitCount, resetForm, setValues }) => (
                    <div className={styles.modal} style={{ ...sideStyle, right: showtrue ? "0" : "-480px" }}>
                        <div className={styles.title}>
                            <span className={styles.close} onClick={() => hide(resetForm)}>
                                &#x2715;
                            </span>
                        </div>
                        <h5 style={{ padding: "10px" }}>{t("Update booking")}</h5>
                        <div></div>
                        <Form autoComplete="off">
                            {change(values)}
                            <div className={`${styles2.bookingContainer} bookingContainer`}>
                                <div className={styles2.bookingKey}>{t("Category")}</div>
                                <Field
                                    component={AntSelect}
                                    name="room_category"
                                    submitCount={submitCount}
                                    options={cats}
                                    onSelect={(e) => onCategoryChange(e, values, setValues)}
                                />
                                <div className={styles2.bookingKey}>{t("Room")}</div>
                                <Field component={AntSelect} name="room_id" submitCount={submitCount} options={rooms} />
                                {/* <Field component={AntInput} value={bookingData.room?.number} submitCount={submitCount} readOnly /> */}
                                <div className={styles2.bookingKey}>{t("Start date")}</div>
                                <Field
                                    component={AntDatePicker}
                                    name="start_date"
                                    onChange={(e) => (values.start_date = e?.$d)}
                                    submitCount={submitCount}
                                    format={"DD.MM.YYYY"}
                                />
                                <div className={styles2.bookingKey}>{t("End date")}</div>
                                <Field
                                    component={AntDatePicker}
                                    name="end_date"
                                    onChange={(e) => (values.end_date = e?.$d)}
                                    submitCount={submitCount}
                                    format={"DD.MM.YYYY"}
                                />
                                <div className={styles2.bookingKey}>{t("Booking type")}</div>
                                <Field component={AntSelect} name="type" submitCount={submitCount} options={types} />
                                <hr />
                                <hr />
                                <div className={styles2.bookingKey}>{t("First name")}</div>
                                <Field component={AntInput} name="first_name" submitCount={submitCount} />
                                <div className={styles2.bookingKey}>{t("Last name")}</div>
                                <Field component={AntInput} name="last_name" submitCount={submitCount} />
                                <hr />
                                <hr />
                                <div className={styles2.bookingKey}>{t("Amount")}</div>
                                <Field component={AntInput} name="debit" submitCount={submitCount} readOnly />
                                <div className={styles2.bookingKey}>{t("Credit")}</div>
                                <Field component={AntInput} name="credit" submitCount={submitCount} />
                                <div className={styles2.bookingKey}>{t("Comment")}</div>
                                <Field component={AntInput} name="comments" submitCount={submitCount} />
                            </div>
                            <Button style={{ float: "right", marginRight: "10px" }} type="primary" htmlType="submit">
                                {isSubmitting ? t("Updating") : t("Update")}
                            </Button>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
}

function dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
