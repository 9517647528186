import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";

const defaultTheme = createTheme();

export default function StepOne() {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    setLoading(true);
    const body = {
      name: event.name,
      email: event.email,
      abbrivation: event.abbrivation,
      partner: event.partner,
    };
    await axios
      .post(`${ENV.API_URL}/hdp-gateway/hotel/register`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoading(false);
        toast.success(t("Sent successfully. We will contact you!"));
        navigate("/login");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(t("An error occurred"));
      });
  };

  const nameRegEx = /^[\w\-. ]+$/;
  const abbrivationRegEx = /^(?=.{5,20}$)(?![_])(?!.*[_]{2})[a-z0-9_]+(?<![_])$/;

  const validate = Yup.object({
    name: Yup.string().required(t("Field is required")).matches(nameRegEx, {message:t("value invalid")}),
    email: Yup.string().email().required(t("Field is required")),
    abbrivation: Yup.string().required(t("Field is required")).matches(abbrivationRegEx, {message:t("value invalid")}),
    partner: Yup.string().required(t("Field is required")),
  });


  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component="main"
        sx={{ height: "90vh", display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={12} sm={8} md={5}>
          <Box
            sx={{
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="card  w-75">
              <div className="card-body">
                {loading && <div className="overlay" />}
                <div className="d-flex justify-content-center">
                  <Typography
                    component="h1"
                    variant="h5"
                    className="Sign_title"
                  >
                    {t("Hotel registration")}
                  </Typography>
                </div>
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    abbrivation: "",
                    partner: "",
                  }}
                  validationSchema={validate}
                  onSubmit={async (values) => {
                    handleSubmit(values);
                    return new Promise((res) => setTimeout(res, 500));
                  }}
                >
                  {({ values, errors }) => (
                    <Form autoComplete="off">
                      <Grid container spacing={2}>
                        <Grid item xs={12} className="register_input">
                          <Field
                            fullWidth
                            size="small"
                            name="name"
                            label={t("Name")}
                            autoComplete="name"
                            component={TextField}
                          />
                        </Grid>
                        <Grid item xs={12} className="register_input">
                          <Field
                            fullWidth
                            size="small"
                            name="abbrivation"
                            label={t("Abbreviation")}
                            autoComplete="abbrivation"
                            component={TextField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            fullWidth
                            select
                            name="partner"
                            size="small"
                            component={TextField}
                            label={t("Select partnership")}
                          >
                            <MenuItem value="partner">{t("Partner")}</MenuItem>
                            <MenuItem value="member">{t("Member")}</MenuItem>
                          </Field>
                        </Grid>
                        <Grid item xs={12} className="register_input">
                          <Field
                            fullWidth
                            size="small"
                            name="email"
                            label={t("Email")}
                            autoComplete="email"
                            component={TextField}
                          />
                        </Grid>
                      </Grid>
                      {loading ? (
                        <Button
                          className="register_input mt-3"
                          loading
                          type="submit"
                          fullWidth
                          disabled={loading}
                          variant="contained"
                        >
                          {t("Register")}
                        </Button>
                      ) : (
                        <Button
                          className="register_input mt-3"
                          type="submit"
                          fullWidth
                          disabled={loading}
                          variant="contained"
                        >
                          {t("Register")}
                        </Button>
                      )}
                      <Grid container>
                        <div className="d-flex justify-content-end mt-3">
                          <Link to="/login">{t("Have you already added your hotel? Login")}</Link>
                        </div>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
