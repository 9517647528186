import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Grid,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import { Button } from "antd";
import Card from "./card";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";
import ENV from "../../environment";
import RoomsFilters from "../Shaxmatka/Filters/Filters";
import RoomsSort from "../Shaxmatka/Sorts/Sort";

// Icons
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import SortRoundedIcon from '@mui/icons-material/SortRounded';


function DataTable() {
  const [rooms, setRooms] = useState([]);
  const [categories, setCategories] = useState([]);
  const [housekeepers, sethousekeepers] = useState([]);
  const [policys, setpolicys] = useState({ data: { check_out_time: "" } });
  const [roomhousekeeper, setroomhousekeeper] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [roomsPerPage] = useState(12);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { t } = useTranslation();
  const [filtersModal, setFiltersModal] = useState(false);
  const [sortsModal, setSortsModal] = useState(false);

  // Filter
  const [filter, setFilterProp] = useState({
    number: null,
    status: "",
    category: "",
    priceRange: [0, Infinity],
    minValue: 0,
    maxValue: Infinity
  });

  // Sort
  const [sort, setSortProp] = useState({
    sortPrice: "asc",
    sortNumber: "asc",
  });

  // Use theme and media query to determine grid item size
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMd = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLg = useMediaQuery(theme.breakpoints.between("lg", "xl"));

  // Calculate grid item size based on screen size
  const gridItemSize = isXs
    ? 12
    : isSm
      ? 6
      : isMd
        ? 4
        : isLg
          ? 3
          : 3; // Default to 3 for larger screens

  const fetchData = async () => {
    setLoading(true);

    try {
      const token = sessionStorage.getItem("token");

      const categoriesResponse = await axios.get(
        `${ENV.API_URL}/hdp-gateway/room/category/list`,
        {
          headers: { Authorization: token },
        }
      );
      setCategories(categoriesResponse.data.data);

      const housekeepersResponse = await axios.get(
        `${ENV.API_URL}/hdp-gateway/user/housekeepers`,
        {
          headers: { Authorization: token },
        }
      );

      sethousekeepers(housekeepersResponse.data.data);
      const roomhousekeeperResponse = await axios.get(
        `${ENV.API_URL}/hdp-gateway/user/rooms-housekeeper`,
        {
          headers: { Authorization: token },
        }
      );
      setroomhousekeeper(roomhousekeeperResponse.data.data);

      const policy = await axios.get(
        `${ENV.API_URL}/hdp-gateway/hotel/policy`,
        {
          headers: { Authorization: token },
        }
      );
      setpolicys(policy.data.data?.data?.check_out_time?.split(":")[0] || 12);

      const currentDate = new Date().toJSON().slice(0, 10);

      const reservationQuery = `?start_date=${currentDate}&end_date=${currentDate}`;
      const reservationsResponse = await axios.get(
        `${ENV.API_URL}/hdp-gateway/booking/v2${reservationQuery}`,
        {
          headers: { Authorization: token },
        }
      );

      const reservations = reservationsResponse.data.data.roomCategories;

      const roomsResponse = await axios.get(`${ENV.API_URL}/hdp-gateway/room`, {
        headers: { Authorization: token },
      });

      const fetchedRooms = roomsResponse.data.data;

      const currentHour = new Date().getHours();
      const today = new Date().toISOString().split("T")[0]; // Extract current date without time

      const roomsWithReservations = fetchedRooms.map((room) => {
        let reservationsForRoom = [];

        reservations.forEach((category) => {
          const foundRoom = category.rooms.find((r) => r.id === room.id);
          if (foundRoom && foundRoom.reservations) {
            reservationsForRoom = foundRoom.reservations.map((reservation) => ({
              ...reservation,
              notice: reservation.noticeData || { credits: 0, debits: 0 }, // Assign notice or fallback
            }));

            // Filtrlaymiz: soatga qarab va end_date ning bugungi kunga tegishli bo'lishiga qaraymiz
            const checkReservations = reservationsForRoom.filter(
              (res) =>
                (currentHour < policys &&
                  new Date(res.end_date).toISOString().split("T")[0] === today) ||
                (currentHour >= policys &&
                  new Date(res.end_date).toISOString().split("T")[0] >= today)
            );

            // Xona statusini yangilash
            if (checkReservations.length > 0) {
              const currentReservation = checkReservations.find(
                (res) => res.bookingStatus === 1 || res.bookingStatus === 2
              );
              if (currentReservation) {
                if (currentReservation.bookingStatus === 1) {
                  room.status_name = "busy";
                  room.status = 6;
                } else if (currentReservation.bookingStatus === 2) {
                  room.status_name = "Booked";
                  room.status = 6;
                }
              }
            }

            // Agar rezervatsiyalar ro'yxatida bookingStatus === 2 bo'lgan rezervatsiya bo'lsa, xonani 'booked' holatiga o'zgartiramiz
            if (reservationsForRoom.some(res => res.bookingStatus === 2)) {
              room.status = 6;
              room.status_name = "Booked";
            }
          }
        });

        return { ...room, reservations: reservationsForRoom };
      });

      setRooms(roomsWithReservations);

      setFilterProp((prev) => {
        return { ...prev, minValue: Math.min(...roomsWithReservations.map((room) => room.price)), maxValue: Math.max(...roomsWithReservations.map((room) => room.price)) }
      });

    } catch (err) {
      toast.error(
        // err?.response?.data?.errors[0]?.message || t("An error occurred")
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setFilterProp((prev) => {
      return { ...prev, minValue: Math.min(...rooms.map((room) => room.price)), maxValue: Math.max(...rooms.map((room) => room.price)) }
    });
  }, [rooms])

  const indexOfLastRoom = currentPage * roomsPerPage;
  const indexOfFirstRoom = indexOfLastRoom - roomsPerPage;
  const currentRooms = rooms
    .filter((room) => {
      if (filter.number) {
        return room?.number?.toLowerCase().includes(filter.number?.toLowerCase());
      }
      return true;
    })
    .filter((room) => {
      if (filter.status) {
        return room.status_name === filter.status;
      }
      return true;
    })
    .filter((room) => {
      if (filter.category) {
        return room.category_name === filter.category;
      }
      return true;
    })
    .filter((room) => {
      return room.price >= (filter.priceRange[0] || 0) && room.price <= (filter.priceRange[1] || Infinity)
    })
    .sort((a, b) => {
      if (sort.sortNumber === "asc") {
        return a.number - b.number;
      } else {
        return b.number - a.number;
      }
    })
    .sort((a, b) => {
      if (sort.sortPrice === "asc") {
        return a.price - b.price;
      } else {
        return b.price - a.price;
      }
    })

  const roomsToShow = currentRooms.slice(indexOfFirstRoom, indexOfLastRoom);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mt-3">
      <h5>{t("All rooms")}</h5>
      <div>
        {(rooms.length > 0 && !loading) && <>
          <div style={{ display: "flex", justifyContent: "flex-end", padding: "0 0 5px 0", gap: "5px" }}>
            <Button type="primary" onClick={() => setFiltersModal(true)}>
              <TuneRoundedIcon />
            </Button>
            <Button type="primary" onClick={() => setSortsModal(true)}>
              <SortRoundedIcon />
            </Button>
          </div>
          <RoomsFilters
            show={filtersModal}
            setShow={setFiltersModal}
            categories={categories}
            filterProp={filter}
            setFilterProp={setFilterProp}
            getDataWithFilter={fetchData}
          />
          <RoomsSort
            show={sortsModal}
            setShow={setSortsModal}
            sortProp={sort}
            setSortProp={setSortProp}
            getDataWithFilter={fetchData}
          />
        </>
        }
        {loading ? (
          <Loading />
        ) : (
          <Box>
            <Grid container spacing={2}>
              {roomsToShow.length > 0 ? (
                roomsToShow.map((room) => (
                  <Grid item key={room.id} xs={12} sm={6} md={4} lg={3}>
                    <Card data={room} housekeepers={housekeepers} roomhousekeeper={roomhousekeeper} categories={categories} fetchData={fetchData} />
                  </Grid>
                ))
              ) : (
                <p style={{ marginTop: '20px' }}>{t("No rooms found")}</p>
              )}
            </Grid>
          </Box>
        )}

        {currentRooms.length > roomsPerPage && (
          <div className="pagination mt-3" style={{ textAlign: "center" }}>
            {[...Array(Math.ceil(currentRooms.length / roomsPerPage)).keys()].map(
              (number) => (
                <Button
                  key={number + 1}
                  onClick={() => paginate(number + 1)}
                  variant={currentPage === number + 1 ? "contained" : "outlined"}
                  color="primary"
                  style={{
                    margin: "0 5px",
                    minWidth: "40px",
                  }}
                >
                  {number + 1}
                </Button>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DataTable;
