import React, { useState, useEffect } from 'react';
import { Typography, Grid, TextField } from "@mui/material";
import ENV from "../../environment";
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const GuestInfoModal = ({ guestData, reservations }) => {
    const [moredata, setmoredata] = useState({});

    const getAxios = async () => {
        if (guestData[0]?.id) {
            try {
                const res = await axios.get(`${ENV.API_URL}/hdp-gateway/guest/${guestData[0]?.id}`, {
                    headers: {
                        authorization: sessionStorage.getItem("token"),
                    },
                });
                setmoredata(res.data.data);
            } catch (err) {
                // Handle error (e.g., show a toast notification)
                console.error(err);
            }
        }
        if (reservations[0]?.guest_id) {
            try {
                const res = await axios.get(`${ENV.API_URL}/hdp-gateway/guest/${reservations[0]?.guest_id}`, {
                    headers: {
                        authorization: sessionStorage.getItem("token"),
                    },
                });
                setmoredata(res.data.data);
            } catch (err) {
                // Handle error (e.g., show a toast notification)
                console.error(err);
            }
        }
    };

    useEffect(() => {
         getAxios();
    }, []);

    const { t } = useTranslation();

    // Filter out reservations where guest_id matches guestData[0]?.id
    const filteredReservations = reservations.filter(
        (reservation) => reservation.guest_id !== guestData[0]?.id
    );

    return (
        <div>
            <Typography variant="h6" style={{ color: "#007bff", marginBottom: "16px" }}>
                {t("Full info about guest")}
            </Typography>
            
            {guestData.map((guestData) => (
                <Grid container spacing={2} style={{ marginBottom: '20px' }} key={guestData.id}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("First name")}
                            value={guestData?.first_name || ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("Last name")}
                            value={guestData?.last_name || ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("Room number")}
                            value={moredata?.room_number || ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("Room category")}
                            value={moredata?.room_category_name || ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("Check in date")}
                            value={moredata?.checked_date ? new Date(moredata.checked_date).toLocaleDateString() : ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("End date")}
                            value={moredata?.end_date ? new Date(moredata.end_date).toLocaleDateString() : ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("Checked by")}
                            value={moredata?.checked || ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("Total Credit")}
                            value={moredata?.total_credit || 0}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("Total Debit")}
                            value={moredata?.total_debit || 0}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
            ))}

            {filteredReservations.map((guestData) => (
                <Grid container spacing={2} key={guestData.booking_id}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("First name")}
                            value={guestData?.first_name || ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("Last name")}
                            value={guestData?.last_name || ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("Room number")}
                            value={moredata?.room_number || ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("Room category")}
                            value={moredata?.room_category_name || ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("start date")}
                            value={moredata?.end_date ? new Date(moredata.start_date).toLocaleDateString() : ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("End date")}
                            value={moredata?.end_date ? new Date(moredata.end_date).toLocaleDateString() : ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("Checked by")}
                            value={moredata?.checked || ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("Total Credit")}
                            value={moredata?.total_credit || 0}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={t("Total Debit")}
                            value={moredata?.total_debit || 0}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
            ))}
        </div>
    );
};

export default GuestInfoModal;
